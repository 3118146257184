import { Layout } from "antd";
import { Outlet } from "react-router-dom";

const { Content } = Layout;

const BaseLayout = () => {
	return (
		<Layout style={{ height: "100vh", width: "100vw" }}>
			<Content
				style={{
					padding: 24,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Outlet />
			</Content>
		</Layout>
	);
};

export default BaseLayout;
