import { CloseOutlined } from "@ant-design/icons";
import {
	Button,
	Card,
	Col,
	Divider,
	Flex,
	Form,
	Input,
	List,
	Row,
	Space,
	Switch,
	Tooltip,
	Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";

const Wheel = () => {
	const [form] = useForm();
	const [items, setItems] = useState<any[]>([]);
	const [index, setIndex] = useState(0);
	const [isSpinning, setIsSpinning] = useState(false);
	const [showSpin, setShowSping] = useState(true);

	const onFinish = (values: any) => {
		setItems((prev) => [...prev, values.item]);
		form.resetFields();
	};

	const getRandomItem = () => {
		setIsSpinning(true);

		const timer = setInterval(() => {
			const randIndex = Math.floor(Math.random() * items.length);

			setIndex(randIndex);
		}, 100);
		setTimeout(() => {
			clearInterval(timer);
			setIsSpinning(false);
		}, 3000);
	};

	const removeItem = (i: number) => {
		let newItems = [...items];
		newItems.splice(i, 1);
		setItems(newItems);
	};

	return (
		<Card>
			<Row gutter={16}>
				<Col span={24}>
					<Form form={form} onFinish={onFinish} layout="vertical">
						<Form.Item name={"item"} label={"Item"}>
							<Space.Compact>
								<Input />
								<Button htmlType="submit">Add Item</Button>
							</Space.Compact>
						</Form.Item>
					</Form>
					<Divider />

					<Space>
						<Switch checked={showSpin} onChange={setShowSping} />
						Show spin
					</Space>
					<Divider />
					<Tooltip
						title={
							items.length < 2
								? "Wheel would need at least 2 items to be able to spin!"
								: ""
						}
					>
						<Button
							disabled={items.length < 2}
							onClick={getRandomItem}
							block
							type="primary"
						>
							Spin
						</Button>
					</Tooltip>
					<Flex justify="center">
						<Typography.Title>{items[index]}</Typography.Title>
					</Flex>
				</Col>
				{items.length !== 0 ? (
					<Col span={24}>
						<List bordered>
							{items?.map((item, i) => (
								<List.Item
									extra={<CloseOutlined onClick={() => removeItem(i)} />}
									style={{
										background:
											(showSpin || !isSpinning) && i === index
												? "beige"
												: "inherit",
									}}
								>
									{item}
								</List.Item>
							))}
						</List>
					</Col>
				) : null}
			</Row>
		</Card>
	);
};

export default Wheel;
