import A_Drone from "../assets/audio/A-440.mp3";
import Bb_Drone from "../assets/audio/Bb-446p16.mp3";
import Eb_Drone from "../assets/audio/Eb-622p25.mp3";
import F_Drone from "../assets/audio/F-698p46.mp3";

export const getDrone = (drone: string) => {
	switch (drone) {
		case "b-flat":
			return Bb_Drone;
		case "a":
			return A_Drone;
		case "e-flat":
			return Eb_Drone;
		case "f":
			return F_Drone;
		default:
			break;
	}
};
