import { Col, Row, Statistic } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const Clock = () => {
	const [time, setTime] = useState(dayjs());

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTime(dayjs());
		}, 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return (
		<Row gutter={16}>
			<Col>
				<Statistic
					title="Current Chronology"
					value={time.format("hh:mm:ss A")}
					valueStyle={{ fontSize: 32 }}
				/>
			</Col>
		</Row>
	);
};

export default Clock;
