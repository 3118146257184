import H5AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

type AudioPlayerProps = {
	file?: string | null;
	header?: string;
};

const AudioPlayer = ({ file, header }: AudioPlayerProps) => {
	return (
		<H5AudioPlayer
			header={header}
			showJumpControls={false}
			showSkipControls={false}
			src={file}
		/>
	);
};

export default AudioPlayer;
