import { useParams } from "react-router-dom";
import AudioPlayer from "./AudioPlayer";
import { getDrone } from "../../../utils/utils";
import { Empty } from "antd";

const Drone = () => {
	const { note } = useParams();
	const file = getDrone(note);
	if (!file)
		return <Empty description={"The drone you requested was not found"} />;

	return <AudioPlayer file={file} />;
};

export default Drone;
