import "./App.css";

import dayjs from "dayjs";
import dayLocaleData from "dayjs/plugin/localeData";
import {
	RouteObject,
	RouterProvider,
	createBrowserRouter,
} from "react-router-dom";
import DateBlocker from "./modules/date-blocker/components/DateBlocker";
// import BaddyHelper from "./modules/baddy-helper/components/BaddyHelper";
import BaseLayout from "./modules/common/components/BaseLayout";
import Drone from "./modules/drone/components/Drone";
import Clock from "./modules/clock/components/Clock";
import Wheel from "./modules/wheel/components/Wheel";

dayjs.extend(dayLocaleData);

const App = () => {
	const routes: Array<RouteObject> = [
		{
			path: "/",
			element: <BaseLayout />,
			children: [
				{ index: true, element: <Clock /> },
				{ path: "/date-blocker", element: <DateBlocker /> },
				{ path: "/wheel", element: <Wheel /> },
				// { path: "/baddy-helper", element: <BaddyHelper /> },
				{
					path: "/drone",
					children: [
						{
							path: ":note",
							element: <Drone />,
						},
					],
				},
			],
		},
	];
	const router = createBrowserRouter(routes);
	return <RouterProvider router={router} />;
};

export default App;
