import { useCallback } from "react";
import {
	Button,
	Calendar,
	Card,
	Col,
	Flex,
	Row,
	Select,
	Space,
	Typography,
} from "antd";
import dayjs from "dayjs";
import dayLocaleData from "dayjs/plugin/localeData";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

dayjs.extend(dayLocaleData);

const currentYear = dayjs().get("year");

const DateBlocker = () => {
	const homeDate = (date: dayjs.Dayjs) => {
		const awayWeeks = 2;

		let homeWeeks = 8;
		let startDate = dayjs().startOf("year").startOf("week");

		if (date.isAfter(dayjs("2024-02-25").add(awayWeeks, "weeks"), "week")) {
			startDate = dayjs("2024-02-25").add(awayWeeks, "weeks").endOf("week");
		} else {
			homeWeeks = 9;
		}

		const cycleLength = homeWeeks + awayWeeks;
		const weekPassed = date.diff(startDate, "week");
		return weekPassed % cycleLength < homeWeeks;
	};

	const cellRender = useCallback((current: number | dayjs.Dayjs, info: any) => {
		if (info.type !== "date") {
			return info.originalNode;
		}
		if (typeof current === "number") {
			return current;
		}

		return (
			<div
				className={`${info.originNode.props.className} ${
					homeDate(current) ? "home" : "away"
				}`}
			>
				{current.date()}
			</div>
		);
	}, []);

	return (
		<Calendar
			mode="month"
			style={{ padding: 16, width: 500 }}
			fullCellRender={cellRender}
			fullscreen={false}
			headerRender={({ value, onChange }) => {
				const start = 0;
				const end = 12;
				const monthOptions = [];

				let current = value.clone();
				const localeData = value.localeData();
				const months = [];
				for (let i = 0; i < 12; i++) {
					current = current.month(i);
					months.push(localeData.monthsShort(current));
				}

				for (let i = start; i < end; i++) {
					monthOptions.push(
						<Select.Option key={i} value={i} className="month-item">
							{months[i]}
						</Select.Option>
					);
				}

				const year = value.year();
				const month = value.month();
				const options = [];
				for (let i = currentYear; i < currentYear + 10; i += 1) {
					options.push(
						<Select.Option key={i} value={i} className="year-item">
							{i}
						</Select.Option>
					);
				}
				return (
					<div style={{ padding: 8 }}>
						<Card>
							<Flex className="legend" gap={8}>
								<Space>
									<div className="legend-item home" />
									<Typography.Text>Home</Typography.Text>
								</Space>
								<Space>
									<div className="legend-item away" />
									<Typography.Text>Away</Typography.Text>
								</Space>
							</Flex>
						</Card>
						<Typography.Title level={4}>
							{value.format("MMMM")}
						</Typography.Title>
						<Row gutter={8}>
							{/* <Col>
										<Radio.Group
											size="small"
											onChange={(e) => onTypeChange(e.target.value)}
											value={type}
										>
											<Radio.Button value="month">Month</Radio.Button>
											<Radio.Button value="year">Year</Radio.Button>
										</Radio.Group>
									</Col> */}
							<Col>
								<Select
									size="small"
									popupMatchSelectWidth={false}
									className="my-year-select"
									value={year}
									onChange={(newYear) => {
										const now = value.clone().year(newYear);
										onChange(now);
									}}
								>
									{options}
								</Select>
							</Col>

							<Col>
								<Space>
									<Button
										size="small"
										icon={<LeftOutlined />}
										onClick={() => onChange(value.clone().month(month - 1))}
										disabled={value.subtract(1, "month").year() < currentYear}
									/>
									<Button
										size="small"
										icon={<RightOutlined />}
										onClick={() => onChange(value.clone().month(month + 1))}
									/>
								</Space>
							</Col>
							<Col>
								<Select
									size="small"
									popupMatchSelectWidth={false}
									value={month}
									onChange={(newMonth) => {
										const now = value.clone().month(newMonth);
										onChange(now);
									}}
								>
									{monthOptions}
								</Select>
							</Col>
						</Row>
					</div>
				);
			}}
		/>
	);
};

export default DateBlocker;
